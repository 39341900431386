<template>
  <div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
      <h2>Information about our Biofuels</h2>
      </div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
      <p> 
        At Budget 2020, the government announced that it would remove the entitlement to use red diesel and tax treatment 
        from most sectors from April 2022. In Budget 2021 it was announced along with the above the government along with 
        the fuel supply industry have now developed greener alternatives to diesel in the hope of resulting of positive 
        environment impacts over the long term. 
        Currently only HVO is allowed to be used in Westminster London and we foresee this expanding to the majority of 
        London soon.
        We at Bens Fuels are now offering Gas to Liquid (GTL) and Hydrated Vegetable Oil (HVO).
      </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>Shell GTL</h4>
      <p style="padding-bottom:0;margin-bottom:15px;">
        Shell GTL (Gas-To-Liquid) Fuel is a cleaner burning diesel alternative fuel to conventional diesel that can 
        be used in both on road and off-road application. 
        Shell GTL is a clean alternative to regular diesel. Many recent diesel problems will be eliminated using GTL.
      </p>
        <h5 style="color:white; font-weight:bold; font-size:1.25em;">Benefits </h5>
      <p>
        <strong>DROP IN AND GO </strong><br>
        The fuel can be used as a direct replacement for conventional diesel fuels in heavy-duty and light-duty engines. 
        Therefore, there is no need for engine modifications, new infrastructure or vehicle investment. 
        <br>

        <strong>BIODEGRADABLE AND NON-TOXIC </strong><br>
        Clean fuel alternative. FAME free.  GTL Fuel is proven to be less harmful to the environment, with tests showing 
        that it can be classified as ‘readily biodegradable’ and non-toxic. 
        <br>
        
        <strong>REDUCED NOISE POLLUTION</strong><br>
        Getting too loud? Noise levels in some engines can be reduced by 1-4 dB with Shell GTL Fuel’s more uniform combustion. 
        <br>

        <strong>STORE SAFER, FOR LONGER </strong><br>
        Shell GTL Fuel has a longer shelf life than conventional diesel. It also offers safer handling and storage 
        characteristics due to a higher flash point. 
        <br>

        <strong>WINTER GRADE FUEL, ALL YEAR ROUND </strong><br>
        Shell GTL Fuel has excellent low temperature performance, with a low cloud point and improved cold start 
        performance due to the high cetane number. Any winter fuel problems many have experienced.
        <br>

        <strong>REDUCTION IN HARMFUL EMISSIONS </strong>
        <br>
        One of the major benefits of using Shell GTL Fuel is the reduction in emissions of regulated pollutants 
        such as Carbon Monoxide, Nitrogen Oxides and Particle Matter 
      </p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row g-0" style="background-color: #335ba3;">
      <div class="col-1"></div>
      <div class="col-10">
        <h4>HVO</h4>
        <p style="padding-bottom:0;margin-bottom:15px;">
        HVO fuel is a paraffinic, premium quality second-generation renewable fuel that provides a cleaner-burning 
        alternative for use in diesel engines. Suitable for use in on-road and off-road uses. It also reduces 
        greenhouse gas emissions by up to 90%.
        HVO fuel is FAME free and fossil fuel free as it is made solely from 100% renewable raw materials. 
        </p>
        <h5 style="color:white; font-weight:bold; font-size:1.25em;">Benefits</h5>
        <p>
          <strong>WINTER GRADE FUEL</strong><br>
          FAME free, eliminates problems of Fuel bug seen in recent years in diesel. 
          <br>
          <strong>DROP IN FUEL</strong> <br>
          No need to change the infrastructure, can be used straight away as diesel alternative. 
          <br>
          <strong> LONGER LIFE</strong> <br>
          Lasts far longer when compared to regular biodiesel.
          <br>
          <strong>REDUCES EMMISSIONS </strong><br> 
          Releases fewer Nitrogen Oxides(NOx), Particulate Matter (PM) and Carbon Dioxide (CO2)
        </p>
      </div>
      <div class="col-1"></div>
    </div>
      
  </div>
</template>

<script>
export default {
    name: 'Biofuels'
}
</script>

<style>
h2
{
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
}

h4
{
  font-size:1.75em;
  color: white;
  padding-bottom: 5px;
}

p
{
  color: white;
  font-weight: 350;
  font-size: 1.15em;
  line-height: 32px;
  padding-bottom: 10px;
}
</style>